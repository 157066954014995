import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ResponseDTO, BecomeDispatcher } from '@models/index';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DispatchService {

  private url:string = environment.baseUrl;
  
  constructor(private http:HttpClient) { }

  becomeDispatcher(data:BecomeDispatcher):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.url+'api/user/become-a-dispatcher', data);
  }

  checkIfUserIsADispatcher(email:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url + 'api/user/user-dispatcher-status/'+email);
  }
}
