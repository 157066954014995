import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseDTO } from '../../../models/response/response';
import { CreateInventory } from '../../../models/inventory/inventory';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http:HttpClient) { }
  private baseUrl : string = environment.baseUrl;

  getInventoryByOutlet(outletId:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Inventory/get-by-outlet/'+outletId);
  }

  getInventoryByCategoryByOutlet(outletId:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Inventory/get-by-category-by-outlet/'+outletId);
  }

  public createInventory(model:CreateInventory):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.baseUrl+'api/inventory', model)
  }

  public editInventory(model:CreateInventory):Observable<ResponseDTO>{
    return this.http.put<ResponseDTO>(this.baseUrl+'api/inventory', model)
  }

  enableDisableInventory(inventory:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Inventory/enable-disable-inventory/'+inventory);
  }

}
