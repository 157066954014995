<div class="flex flex-col sm:px-1 py-0.5">
  <div class="group" (click)="clickToActive(index)"
    [ngClass]="{'category_item_div_active' : active, 'category_item_div':!active}">
    <span class="flex items-center group-hover:text-[#FAFAFA]">
      <svg class="stroke-[#0F172A] group-hover:stroke-[#FAFAFA]" [ngClass]="{'stroke-[#FAFAFA]' : active}" width="16"
        height="16" viewBox="0 0 18 18" fill="#0f172a08" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <a class="px-2 text-[#0F172A] group-hover:text-white" [ngClass]="{'text-white' : active}" href="">{{menuName}}</a>
    </span>
    <span class="text-[#0F172A] opacity-50 group-hover:text-white" [ngClass]="{'text-white' : active}">
      {{menuProp}}
    </span>
  </div>
</div>