<!-- Entire Div -->
<div class="flex">
  <div class="flex justify-center w-[13%] sm:w-[5%] h-fit px-1 relative">
    <p-sidebar [(visible)]="sidebarVisible">
      <section class="flex flex-col items-center pt-1 bg-white 2xl:px-2">
        <div class="mb-4 text-sm font-semibold lg:text-2xl text-vendblocblue">My Dashboard</div>
        <img src="https://res.cloudinary.com/dw5cv0yz0/image/upload/v1718293820/VendBloc/dashboarduser_q5ex38.png" class="rounded-full w-[50px] lg:w-[150px]" alt="profile image" />
        <span
          class="flex text-center  text-sm lg:text-2xl font-semibold text-[#0F172AB2] mt-2">{{user?.name}}</span>
        <div class="flex flex-col w-[90%] mt-5">
          @if(user?.role !== 'customer'){
          <app-side-menu-item (click)="navigate($event,'my-outlets')" menuName="My Businesses"></app-side-menu-item>
          <app-side-menu-item (click)="navigate($event,'my-outlets')" menuName="Pick-Ups"></app-side-menu-item>
          <app-side-menu-item (click)="navigate($event,'info')" menuName="Personal Information"></app-side-menu-item>
          }
          <app-side-menu-item (click)="navigate($event,'my-orders')" menuName="My Orders"></app-side-menu-item>
          <app-side-menu-item (click)="logout()" menuName="Log Out"></app-side-menu-item>
        </div>
      </section>
    </p-sidebar>
    
   <div class="fixed flex flex-col space-y-8 mt-7">
      <p-button size="large" pTooltip="Expand Menu" tooltipPosition="right" (click)="sidebarVisible = true"
        icon="pi pi-bars"></p-button>
      @if(user?.role !== "customer"){
      <p-button size="large" pTooltip="My Business" tooltipPosition="right" (click)="navigate($event,'my-outlets')"
        icon="pi pi-shop" />
      <p-button size="large" pTooltip="PickUp Station" tooltipPosition="right" (click)="navigate($event,'pickup')"
        icon="pi pi-box" />
        @if(user?.role !== "storeowner"){
          <p-button size="large" pTooltip="Dispatchers" tooltipPosition="right" (click)="navigate($event,'dispatchers')"
          icon="pi pi-send" />
        }
      }
        <p-button size="large" pTooltip="My Profile" tooltipPosition="right" (click)="navigate($event,'info')"
        icon="pi pi-user-edit" />
      <p-button size="large" pTooltip="My Orders" tooltipPosition="right" (click)="navigate($event,'my-orders')"
        icon="pi pi-shopping-cart" />
      <p-button size="large" pTooltip="Sign Out" tooltipPosition="right" (click)="logout()" icon="pi pi-sign-out" />
    </div>
  </div>
  <div class="w-[87%] md:w-[90%] flex-grow h-auto min-h-[500px] bg-[#F2F2F2] flex flex-col px-4 2xl:px-20 py-4">
    <ng-content></ng-content>
  </div>
</div>